<template lang="pug">
VDropdown(v-model:shown='shown', :theme='theme', v-bind='$attrs')
	slot(name='label')
	template(#popper)
		slot
</template>

<script lang="ts" setup>
import { Themes } from '~/models/floating-vue';

/**
 * Model:shown
 */
const shown = defineModel<boolean>('shown');

/**
 * Define options
 */
defineOptions({
	inheritAttrs: false,
});

/**
 * Define props
 */
const { theme = Themes.BASE } = defineProps<{
	theme?: (typeof Themes)[keyof typeof Themes];
}>();
</script>
